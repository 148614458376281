<template>
  <div>
    <div class="title flexB" style="display: flex;">
      <h1>{{ $t("top-select-live-data-allocation") }}</h1>
      <div>
        <!--        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >-->
        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
      </div>
    </div>
    <div class="contents liveList">
      <div class="box one filter" style="display: flex;flex-direction: row;flex-wrap: wrap;">
        <div class="flex">
          <p class="bold">{{ $t("search-keyword-title") }}</p>
          <select v-model="keywordType">
            <option value="all">{{ $t("search-keyword-all") }}</option>
            <option value="viewId">{{ $t("live-data-ch-allocation-serial-num") }}</option>
            <option value="channelId">{{ $t("live-data-channel-id") }}</option>
            <option value="eventName">{{ $t("event-data-site-name") }}</option>
            <option value="eventViewId">{{ $t("event-data-site-num") }}</option>
          </select>
          <input
              type="text"
              v-model="keyword"
              @keydown.enter="search"
          />
        </div>
        <div class="flexC">
          <p class="bold">{{ $t("live-data-broadcast-start") }}</p>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span>~</span>
          <Datepicker
              v-model="endYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
        <div class="buttonWrap" style="width:100%; display: flex; margin-top: 15px;">
          <button class="point medium" @click="search" style="width: 120px; margin-left: 0px;">
            {{ $t("btn-searching") }}
          </button>
          <button class="medium margin6" @click="handleReset" style="width: 120px;">
            {{ $t("btn-reset") }}
          </button>
        </div>
      </div>
      <div class="box one">
        <h2 class="bold">
          {{ $t("data-total") }}
          <span class="blue">{{ total }}</span
          >{{ $t("data-case-ea") }}
        </h2>
        <!-- <div class="none">
            <p>{{ $t("search-not-found") }}</p>
        </div>-->

          <table>
            <tr>
              <th>No</th>
              <th>{{ $t("live-data-ch-allocation-serial-num") }}</th>
              <th>{{ $t("live-data-channel-id") }}</th>
              <th>{{ $t("live-data-broadcast-start") }}</th>
              <th>{{ $t("live-data-broadcast-end") }}</th>
              <th>{{ $t("event-data-site-num") }}</th>
              <th>{{ $t("event-data-site-name") }}</th>
              <!-- <th>기기구분</th>
              <th>기기명</th> -->
              <th>{{ $t("live-data-channel-operator") }}</th>
              <th>{{ $t("live-data-channel-phone") }}</th>
              <!-- <th>채널상태</th> -->
            </tr>
            <tr v-if="total == 0">
              <td colspan="9" style="text-align: center;">
                {{ $t("search-not-found") }}
              </td>
            </tr>
            <tr v-for="(data, i) in channelList" :key="i">
              <td>{{ total - (currentPage * size + i) }}</td>
              <td>{{ data.viewId }}</td>
              <td>{{ data.channelId }}</td>
              <td>
                {{
                  data.assignedDate
                      ? moment(data.assignedDate).format("YYYY.MM.DD HH:mm:ss")
                      : "-"
                }}
              </td>
              <td>
                {{
                  data.unassignedDate
                      ? moment(data.unassignedDate).format("YYYY.MM.DD HH:mm:ss")
                      : "-"
                }}
              </td>
              <td>{{ data.eventViewId != null ? data.eventViewId : data.eventId }}</td>
              <td>{{ data.eventTitle }}</td>
              <td>{{ data.userName }}</td>
              <td>{{ getMask(data.userMobileNumber) }}</td>
            </tr>
          </table>
        <div class="pagination">
          <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              :page-size="size"
              :current-page="currentPage + 1"
              @current-change="handleCurrentChange"

          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.title{margin-top: 0;}
</style>
<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { fetchChannelAssignList } from "@/api/channel";
import { format } from "@/mixins/format";
export default {
  components: { Datepicker },
  mixins: [format],
  name: "ChannelList",
  data() {
    return {
      ko: ko,
      moment: moment,
      channelList: [],
      total: 0,
      size: 10,
      currentPage: 0,
      // dateRange: { from: "", to: "" },
      startYmd: null,
      endYmd: null,
      keyword: null,
      keywordType: "all",
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 8, sub: 1 });
  },
  mounted() {
    this.callFunctionsWhenMounted();
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    search(){
      if(this.startYmd != null || this.endYmd != null) {
        if (this.startYmd == null || this.endYmd == null || this.startYmd > this.endYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }
      this.currentPage = 0;
      this.getChannelList();
    },
    getChannelList() {
      let params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        keywordType: this.keywordType,
        keyword: this.keyword,
      };
      params["assignedDateRange.from"] = null;
      params["assignedDateRange.to"] = null;
      if (this.startYmd != null && this.endYmd != null) {
        let from = this.startYmd ? moment(this.startYmd).format("YYYY-MM-DD") : null;
        let to = this.endYmd ? moment(this.endYmd).format("YYYY-MM-DD") : null;
        params["assignedDateRange.from"] = from;
        params["assignedDateRange.to"] = to;
      }

      fetchChannelAssignList(params).then((res) => {
        if (res.data.result == 0) {
          this.total = res.data.data.total;
          this.channelList = res.data.data.content;

        } else {
          let message = res.data.data.message;
          alert(message);
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val -1 ;
      this.getChannelList();
    },
    handleReset() {
      this.currentPage = 0;
      this.keywordType = "all";
      this.keyword = null;
      this.startYmd = null;
      this.endYmd = null;
    },
    handleRefresh() {
      this.handleReset();
      this.callFunctionsWhenMounted();
    },
    callFunctionsWhenMounted(){
      this.search();
    }
  },
};
</script>
